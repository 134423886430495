body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/** loader animation */
.loader-root {
  text-align: center;
}

.loader {
  align-items: center;
  display: flex;
  font-size: 28px;
  height: 100vh;
  padding-left: 20px;
  position: relative;
}

.loader:before {
  animation: loading 2s linear infinite;
  content: attr(data-text);
  left: 20px;
  max-width: 180px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

@keyframes loading {
  0% {
    max-width: 0;
  }
}

.loader-light {
  color: rgba(0, 0, 0, .3);
}

.loader-light:before {
  color: rgba(0, 0, 0, .5);
}

.loader-dark {
  background-color: rgba(0, 0, 0, .8);
  color: rgba(255, 255, 255, .3);
}

.loader-dark:before {
  color: rgba(255, 255, 255, .5);
}


/** hide scrollbar */

::-webkit-scrollbar {    
  width: 6px!important;
  height: 6px!important;
}

::-webkit-scrollbar-track {
  background-color: initial;
}

::-webkit-scrollbar-thumb {
  background-color: gray !important;
  border-radius: 10px !important;
}

/** react-toastify */
.Toastify__toast-body {
  color: #000000;
  font-size: 13px;
}

.pill{
  padding: 0px 4px;
  border-radius: 3px;
  margin-left: 6px;
  background-color: rgba(255,160,58,0.72);
}

.Toastify__toast {
  align-items: center;
  border-radius: 6px !important;
  /* margin: 12px !important; */
  max-width: 350px !important;
  min-height: 50px !important;
  /* padding: 12px !important; */
}

/* .Toastify__close-button {
  padding: 6px !important;
} */

/** hide date selection static rage in mobile view */
@media only screen and (max-width: 720px) {
  /* For tablets: */
  .rdrDefinedRangesWrapper{    
    display: none;
  }
}